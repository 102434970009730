// eslint-disable-next-line import/prefer-default-export
export function getFallbackTimezone(): string {
    return process.env.REACT_APP_FALLBACK_TIMEZONE || 'America/New_York';
}

export async function createBlobFromBase64String(
    base64String: string,
    contentType: string,
): Promise<Blob> {
    return fetch(`data:${contentType};base64,${base64String}`).then(
        async (res) => res.blob(),
    );
}

export async function sleep(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
