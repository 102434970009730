function generateRandomId(): string {
    return `${Date.now().toString(16)}${Math.round(
        Math.random() * Date.now(),
    ).toString(16)}`;
}

export type BackgroundMessage = {
    /**
     * if the receiving frame should respond/acknowledge the message,
     * pass in an `id` that can be used to identify the response.
     */
    id?: string;
    /**
     * origin of the message
     */
    source: string;
    /**
     * targets of the message
     */
    targets: readonly string[];
    /**
     * action to perform
     */
    action: string;
    /**
     * additional data to pass along with the message
     */
    [key: string]: unknown | Record<string, unknown>;
};

export const MESSAGE_TARGET_BACKGROUND = 'vetspireExtensionBackground';

export const MESSAGE_TARGET_CONTENT = 'vetspireExtensionContent';
export const MESSAGE_TARGET_TWEAKS = 'vetspireExtensionTweaks';
export const MESSAGE_TARGET_NAVIGATION = 'vetspireExtensionNavigation';

export const MESSAGE_SOURCE_FLYOUT = 'vetspireExtensionFlyout';
export const MESSAGE_SOURCE_NAVIGATION = 'vetspireExtensionNavigation';

type BackgroundResponse = Omit<BackgroundMessage, 'action'> & {
    id: string;
    action: 'response';
    [key: string]: unknown;
};

type PendingResponse = {
    resolve: (response: unknown | null | PromiseLike<unknown | null>) => void;
    reject: (error: Error) => void;
    timeout: number;
};

type MessageListener = {
    source: string;
    callback: (message: BackgroundMessage) => void;
};

const PENDING_RESPONSES: Record<string, PendingResponse> = {};
const LISTENERS: Record<string, MessageListener> = {};

let listenerInitialized = false;

function handleBackgroundMessage(message: BackgroundMessage): void {
    const { targets } = message;

    if (targets?.length) {
        for (const listener of Object.values(LISTENERS)) {
            if (targets.includes(listener.source)) {
                listener.callback(message);
            }
        }
    }
}

function onBackgroundMessage(event: MessageEvent<unknown>) {
    const message = event.data as BackgroundResponse;

    if (!message || !message.action) {
        return;
    }

    switch (message.action) {
        case 'response': {
            const { id } = message;

            if (!id) {
                console.warn(
                    'received background response without an ID:',
                    message,
                );
                return;
            }

            const pendingResponse = PENDING_RESPONSES[id];

            if (pendingResponse) {
                delete PENDING_RESPONSES[id];
                window.clearTimeout(pendingResponse.timeout);
                pendingResponse.resolve(message);
            }
            break;
        }
        default:
            handleBackgroundMessage(message as BackgroundMessage);
            break;
    }
}

function initializeListener(): void {
    if (!listenerInitialized) {
        listenerInitialized = true;
        window.addEventListener('message', onBackgroundMessage, false);
    }
}

export function addBackgroundMessageListener(
    source: string,
    onMessage: (message: BackgroundMessage) => void,
): () => void {
    const id = generateRandomId();
    LISTENERS[id] = {
        source,
        callback: onMessage,
    };
    initializeListener();

    return () => {
        delete LISTENERS[id];

        if (Object.keys(LISTENERS).length === 0) {
            window.removeEventListener('message', onBackgroundMessage);
            listenerInitialized = false;
        }
    };
}

export function sendBackgroundMessage(
    source: string,
    targets: readonly string[],
    action: string,
    data?: Record<string, unknown>,
): void;

export function sendBackgroundMessage(
    source: string,
    targets: readonly string[],
    action: string,
    data: Record<string, unknown>,
    waitForResponse: false,
): void;

export function sendBackgroundMessage(
    source: string,
    targets: readonly string[],
    action: string,
    data: Record<string, unknown>,
    waitForResponse: true,
    timeoutSeconds?: number,
): Promise<unknown | null>;

export function sendBackgroundMessage(
    source: string,
    targets: readonly string[],
    action: string,
    data: Record<string, unknown> = {},
    waitForResponse = false,
    timeoutSeconds = 10,
): Promise<unknown | null> {
    return new Promise<unknown | null>((resolvePromise, rejectPromise) => {
        const id = waitForResponse ? generateRandomId() : undefined;
        window.parent.postMessage(
            {
                ...data,
                id,
                source,
                targets,
                action,
            } as BackgroundMessage,
            '*',
        );

        if (waitForResponse) {
            initializeListener();
            const idString = id as string;
            PENDING_RESPONSES[idString] = {
                resolve: resolvePromise,
                reject: rejectPromise,
                timeout: window.setTimeout(() => {
                    if (PENDING_RESPONSES[idString]) {
                        delete PENDING_RESPONSES[idString];
                        rejectPromise(
                            new Error(
                                `Timed out waiting for response to ${action}`,
                            ),
                        );
                    }
                }, timeoutSeconds * 1000) as number,
            };
        } else {
            resolvePromise(null);
        }
    });
}
