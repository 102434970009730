import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import Select, { type ActionMeta, type MultiValue } from 'react-select';
import lookupStyles from 'components/LookupSelect/LookupSelect.module.scss';
import type { ProviderSlot } from '../../types';
import styles from './SelectExpandedAccessSlots.module.scss';

type SelectExpandedAccessSlotsPros = {
    slots: readonly ProviderSlot[];
    onChange: (value: readonly ProviderSlot[]) => void;
    readonly: boolean;
};

function isOptionSelected(option: ProviderSlot): boolean {
    return option.availableForExtendedAccess;
}

export default function SelectExpandedAccessSlots({
    slots,
    onChange,
    readonly,
}: SelectExpandedAccessSlotsPros) {
    const translate = useTranslate();
    const selected = React.useMemo(
        () => slots.filter((slot) => slot.availableForExtendedAccess),
        [slots],
    );
    const handleChange = React.useCallback(
        (
            newValues: MultiValue<ProviderSlot>,
            actionMeta: ActionMeta<ProviderSlot>,
        ) => {
            switch (actionMeta.action) {
                case 'remove-value':
                case 'deselect-option': {
                    const removedValue =
                        actionMeta.removedValue || actionMeta.option;

                    onChange(
                        slots.map((slot) => ({
                            ...slot,
                            availableForExtendedAccess:
                                slot.availableForExtendedAccess &&
                                slot.time !== removedValue?.time,
                        })),
                    );
                    break;
                }
                default: {
                    const selectedTimes = newValues.map((slot) => slot.time);

                    onChange(
                        slots.map((slot) => ({
                            ...slot,
                            availableForExtendedAccess: selectedTimes.includes(
                                slot.time,
                            ),
                        })),
                    );
                    break;
                }
            }
        },
        [onChange, slots],
    );

    return (
        <>
            <label
                htmlFor="select-expanded-access-slots"
                className={styles.label}
            >
                {translate(
                    'vetspireExtension.intradayCapacity.selectExpandedAccessSlots.label',
                )}
            </label>
            <Select<ProviderSlot, true>
                isMulti
                id="select-expanded-access-slots"
                name="expandedAccessSlots"
                value={selected}
                className={classnames(lookupStyles.lookup, styles.select)}
                classNamePrefix="lookup-select"
                menuPosition="fixed"
                menuPlacement="auto"
                hideSelectedOptions={false}
                onChange={handleChange}
                options={slots}
                isDisabled={readonly}
                isOptionSelected={isOptionSelected}
            />
        </>
    );
}
