import { gql } from '@apollo/client';
import type { OperationResult } from '@bondvet/types';
import { FeeProductType } from '@bondvet/types/noShows';

// eslint-disable-next-line import/prefer-default-export
export const verifyCreditCard = gql`
    mutation verifyCreditCard(
        $sourceId: String!
        $clientId: String!
        $markAsDefault: Boolean
        $origin: VerifyCreditCardOrigin!
    ) {
        verifyCreditCard(
            sourceId: $sourceId
            clientId: $clientId
            markAsDefault: $markAsDefault
            origin: $origin
        ) {
            success
            error
        }
    }
`;

export type VerifyCreditCardResult = {
    verifyCreditCard: OperationResult;
};

export const deleteCreditCard = gql`
    mutation deleteCreditCard($sourceId: String!, $clientId: String!) {
        deleteCreditCard(sourceId: $sourceId, clientId: $clientId) {
            success
            error
        }
    }
`;

export type DeleteCreditCardResult = {
    deleteCreditCard: OperationResult;
};

export const setDefaultCard = gql`
    mutation setDefaultCreditCard($sourceId: String!, $clientId: String!) {
        setDefaultCreditCard(sourceId: $sourceId, clientId: $clientId) {
            success
            error
        }
    }
`;

export type SetDefaultCardResult = {
    setDefaultCreditCard: OperationResult;
};

export const sendSetupLink = gql`
    mutation sendSetupLink($clientId: String!) {
        sendSetupLink(clientId: $clientId) {
            success
            error
        }
    }
`;

export type SendSetupLinkResult = {
    sendSetupLink: OperationResult;
};

export const chargeInvoice = gql`
    mutation chargeInvoice($invoiceId: String!) {
        chargeInvoice(invoiceId: $invoiceId) {
            success
            error
        }
    }
`;

export type ChargeInvoiceResult = {
    chargeInvoice: OperationResult;
};

export type ChargeInvoiceVariables = {
    invoiceId: string;
};

export const chargeFeeProduct = gql`
    mutation chargeFeeProduct(
        $feeProductType: FeeProductType!
        $clientId: ID!
        $locationId: ID!
    ) {
        chargeFeeProduct(
            feeProductType: $feeProductType
            clientId: $clientId
            locationId: $locationId
        ) {
            success
            error
            invoiceId
        }
    }
`;

export type ChargeFeeProductResult = {
    chargeFeeProduct: OperationResult & {
        invoiceId?: string;
    };
};

export type ChargeFeeProductVariables = {
    feeProductType: FeeProductType;
    clientId: string;
    locationId: string;
};

export const addCreditMemo = gql`
    mutation addCreditMemo(
        $clientId: ID!
        $amount: Decimal!
        $locationId: ID
        $note: String!
    ) {
        addCreditMemo(
            clientId: $clientId
            input: { amount: $amount, locationId: $locationId, note: $note }
        ) {
            amount
            id
            note
            datetime
        }
    }
`;

export interface AddCreditMemoResult {
    addCreditMemo: {
        amount: string;
        id: string;
        note: string;
        datetime: string;
    };
}

export interface AddCreditMemoVariables {
    clientId: string;
    amount: string;
    locationId: string | null;
    note: string;
}

export const updatePatientTags = gql`
    mutation addPatientTag($patientId: ID!, $patientTagIds: [ID]!) {
        updatePatient(id: $patientId, input: { tagIds: $patientTagIds }) {
            id
            tags {
                id
            }
        }
    }
`;

export interface UpdatePatientTagsResult {
    updatePatient: {
        id: string;
        tags: ReadonlyArray<{ id: string }>;
    };
}

export interface UpdatePatientTagsVariables {
    patientId: string;
    patientTagIds: ReadonlyArray<string>;
}
