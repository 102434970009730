import * as React from 'react';
import { query, where, onSnapshot, QueryConstraint } from 'firebase/firestore';
import { subSeconds } from 'date-fns';

import {
    LocationConversationInfo,
    TEXT_MESSAGING_EVENTS_FIREBASE_COLLECTION_NAME,
} from '@bondvet/types/textMessaging';

import Dropdown from 'components/Form/Dropdown';

import useTranslate from 'hooks/useTranslate';
import useLocationId from 'hooks/useLocationId';
import useFirebaseContext from 'hooks/firebase/useFirebaseContext';
import useTextMessagingQuery from 'hooks/useTextMessagingQuery';

import { useLazyQuery } from '@apollo/client';
import ConversationPane from './components/ConversationPane';
import ClientSearch from './components/ClientSearch';
import FaqModal from './components/FaqModal';
import {
    PhoneHasOpenTicketsQueryResult,
    PhoneHasOpenTicketsQueryVariables,
    phoneNumberHasOpenTickets,
    cachedUserConversationHistory,
    CachedUserConversationHistoryQueryResult,
    CachedUserConversationHistoryQueryVariables,
    recentLocationConversations,
    RecentLocationConversationsQueryResult,
    RecentLocationConversationsQueryVariables,
    ClientWithPatients,
    SyncRecentUserConversationHistoryResult,
    SyncRecentUserConversationHistoryVariables,
    syncRecentUserConversationHistory,
    ClientsWithPatientsSearchResult,
    ClientsWithPatientsSearchVariables,
    clientsWithPatientsSearch,
    UpdateConversationUnreadStatusMutationResult,
    UpdateConversationUnreadStatusMutationVariables,
    updateConversationUnreadStatusMutation,
    VetspireClientWithPatients,
} from './api';

import RecentLocationConversations from './components/RecentLocationConversations';

import styles from './Texting.module.scss';
import useTextMessagingMutation from '../../hooks/useTextMessagingMutation';

const LIMIT = 30;
export const TEXTING_SELECTED_CLIENT = 'texting_selected_client';

const SORTS = ['unread', 'oldestToNewest', 'newestToOldest'] as const;
type RecentConversationsSort = (typeof SORTS)[number];

const Texting: React.FunctionComponent = () => {
    const translate = useTranslate();
    const { getCollection } = useFirebaseContext();
    const locationId = useLocationId();

    const [showFaqs, setShowFaqs] = React.useState<boolean>(false);
    const [selectedClient, setSelectedClient] =
        React.useState<ClientWithPatients | null>(null);
    const [recentConversationSort, setRecentConversationSort] =
        React.useState<RecentConversationsSort>('unread');

    const [hasMore, setHasMore] = React.useState(true);
    const [scrollToMessageId, setScrollToMessageId] = React.useState<
        string | undefined | null
    >(undefined);
    const [eventTriggeredReload, setEventTriggeredReload] =
        React.useState<boolean>(false);
    const [initialRecentHistorySync, setInitialRecentHistorySync] =
        React.useState<boolean>(true);
    const [
        updatingConversationUnreadStatus,
        setUpdatingConversationUnreadStatus,
    ] = React.useState<boolean>(false);

    const [afterId, setAfterId] = React.useState<string | undefined | null>(
        undefined,
    );

    const [lastEventId, setLastEventId] = React.useState<
        string | undefined | null
    >(undefined);
    const [
        sortedRecentLocationConversations,
        setSortedRecentLocationConversations,
    ] = React.useState<LocationConversationInfo[] | undefined>(undefined);

    const [unread, setUnread] = React.useState(false);

    const {
        data: recentLocationConversationsData,
        loading: recentLocationConversationsLoading,
        refetch: refetchRecentLocationConversations,
    } = useTextMessagingQuery<
        RecentLocationConversationsQueryResult,
        RecentLocationConversationsQueryVariables
    >(recentLocationConversations, {
        fetchPolicy: 'no-cache',
        skip: !locationId,
        variables: {
            locationId,
        },
    });

    React.useEffect(() => {
        const relatedRecentConversation =
            recentLocationConversationsData?.recentLocationConversations?.find(
                (conversation) =>
                    conversation.vetspireClientId === selectedClient?.id,
            );

        if (relatedRecentConversation) {
            setUnread(relatedRecentConversation.unread);
        }
    }, [recentLocationConversationsData, setUnread, selectedClient]);

    const setClientFromSearch = React.useCallback(
        (client: VetspireClientWithPatients) => {
            setSelectedClient({
                id: client.id,
                familyName: client.familyName || '',
                givenName: client.givenName || '',
                pets: client.patients.map((patient) => ({
                    id: patient.id,
                    name: patient.name,
                    inactive: !patient.isActive,
                    deceased: patient.isDeceased,
                    sex: patient.sex,
                })),
                preferredPhoneNumber: client.preferredPhoneNumber?.value || '',
                optedIn: !!client.externalCommPreferences.find(
                    (pref) => !!pref.postcardMarketing,
                ),
            });
        },
        [setSelectedClient],
    );
    const [searchClients] = useLazyQuery<
        ClientsWithPatientsSearchResult,
        ClientsWithPatientsSearchVariables
    >(clientsWithPatientsSearch);

    React.useEffect(() => {
        let clientId = sessionStorage.getItem(TEXTING_SELECTED_CLIENT);
        if (!clientId) {
            if (
                sortedRecentLocationConversations &&
                sortedRecentLocationConversations.length > 0
            ) {
                clientId =
                    sortedRecentLocationConversations[0].vetspireClientId;
            }
        }
        if (clientId) {
            searchClients({
                variables: {
                    clientId,
                    query: '',
                },
            }).then(({ data }) => {
                if (data?.searchAccounts.length === 1) {
                    const [prevSelectedClient] = data.searchAccounts;
                    setClientFromSearch(prevSelectedClient);
                }
            });
        }
    }, [searchClients, sortedRecentLocationConversations, setClientFromSearch]);

    const storageEventListener = React.useCallback(
        (event: Event) => {
            const storageEvent = event as StorageEvent;

            if (storageEvent.key === TEXTING_SELECTED_CLIENT) {
                const clientId = storageEvent.newValue || undefined;

                if (clientId) {
                    searchClients({
                        variables: {
                            clientId,
                            query: '',
                        },
                    }).then(({ data }) => {
                        if (data?.searchAccounts.length === 1) {
                            const [client] = data.searchAccounts;
                            if (client) {
                                setClientFromSearch(client);
                            }
                        }
                    });
                }
            }
        },
        [searchClients, setClientFromSearch],
    );

    React.useEffect(() => {
        document.addEventListener('storage', storageEventListener);

        return (): void => {
            document.removeEventListener('click', storageEventListener);
        };
    }, [storageEventListener]);

    const {
        data: hasOpenTicketsData,
        loading: hasOpenTicketsLoading,
        refetch: refetchOpenTicketStatus,
    } = useTextMessagingQuery<
        PhoneHasOpenTicketsQueryResult,
        PhoneHasOpenTicketsQueryVariables
    >(phoneNumberHasOpenTickets, {
        fetchPolicy: 'no-cache',
        skip: !selectedClient?.preferredPhoneNumber,
        variables: {
            phoneNumber: selectedClient?.preferredPhoneNumber || '',
        },
    });

    const {
        data: cachedConversationHistoryData,
        loading: cachedConversationHistoryLoading,
        refetch: refetchCachedConversationHistory,
        fetchMore: fetchMoreCachedConversationHistory,
    } = useTextMessagingQuery<
        CachedUserConversationHistoryQueryResult,
        CachedUserConversationHistoryQueryVariables
    >(cachedUserConversationHistory, {
        fetchPolicy: 'network-only',
        skip: !selectedClient?.id,
        notifyOnNetworkStatusChange: true,
        variables: {
            phoneNumber: selectedClient?.preferredPhoneNumber || '',
            limit: LIMIT,
        },
        onCompleted: (result) => {
            if (result.cachedUserConversationHistory.length < LIMIT) {
                setHasMore(false);
            }

            refetchOpenTicketStatus().then();

            const filteredMessages =
                result.cachedUserConversationHistory.filter(
                    (message) =>
                        message.type !== 'pending' &&
                        (message.direction === 'in' ||
                            message.direction === 'out'),
                );

            if (filteredMessages.length > 0) {
                const lastMessage =
                    filteredMessages[filteredMessages.length - 1];

                if (
                    !afterId ||
                    (afterId !== lastMessage?._id && afterId < lastMessage?._id)
                ) {
                    setAfterId(lastMessage?._id ?? null);
                }
            } else {
                setAfterId(null);
            }
        },
    });

    const {
        loading: recentConversationHistoryLoading,
        refetch: resyncRecentConversations,
    } = useTextMessagingQuery<
        SyncRecentUserConversationHistoryResult,
        SyncRecentUserConversationHistoryVariables
    >(syncRecentUserConversationHistory, {
        fetchPolicy: 'cache-first',
        skip:
            !selectedClient?.preferredPhoneNumber ||
            !selectedClient?.id ||
            afterId === undefined ||
            afterId === lastEventId ||
            eventTriggeredReload,
        variables: {
            vetspireClientId: selectedClient?.id || '',
            afterId: afterId || undefined,
            phoneNumber: selectedClient?.preferredPhoneNumber || '',
            limit: LIMIT,
        },
        onCompleted: (data) => {
            if (data?.syncRecentUserConversationHistory?.newMessages) {
                if (initialRecentHistorySync) {
                    setInitialRecentHistorySync(false);
                }
                setScrollToMessageId(null);
                refetchOpenTicketStatus().then();
                if (data.syncRecentUserConversationHistory.newMessages > 0) {
                    refetchCachedConversationHistory().then();
                }
                setEventTriggeredReload(false);

                refetchRecentLocationConversations().then();
            }
        },
    });

    const [
        updateConversationUnreadStatus,
        { loading: loadingUpdateConversationUnreadStatus },
    ] = useTextMessagingMutation<
        UpdateConversationUnreadStatusMutationResult,
        UpdateConversationUnreadStatusMutationVariables
    >(updateConversationUnreadStatusMutation, {
        onCompleted: (data) => {
            if (data?.updateConversationUnreadStatus.success) {
                refetchRecentLocationConversations().then();
            }
        },
    });

    const markAsReadOrUnread = React.useCallback(
        (newUnreadStatus: boolean) => {
            if (
                locationId &&
                selectedClient?.id &&
                selectedClient?.preferredPhoneNumber
            ) {
                setUpdatingConversationUnreadStatus(true);
                updateConversationUnreadStatus({
                    variables: {
                        input: {
                            unread: newUnreadStatus,
                            vetspireLocationId: locationId,
                            vetspireClientId: selectedClient?.id,
                            clientPhoneNumber:
                                selectedClient?.preferredPhoneNumber,
                        },
                    },
                    onCompleted: (result) => {
                        setUpdatingConversationUnreadStatus(false);
                        if (result.updateConversationUnreadStatus.success) {
                            setUnread(newUnreadStatus);
                            refetchRecentLocationConversations().then();
                        }
                    },
                }).then();
            }
        },
        [
            locationId,
            selectedClient,
            updateConversationUnreadStatus,
            refetchRecentLocationConversations,
        ],
    );

    const loadMore = React.useCallback(async () => {
        if (
            recentConversationHistoryLoading ||
            cachedConversationHistoryLoading
        ) {
            return;
        }

        await fetchMoreCachedConversationHistory({
            variables: {
                offset:
                    (cachedConversationHistoryData
                        ?.cachedUserConversationHistory.length || 0) + 1,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (previousResult.cachedUserConversationHistory?.length > 0) {
                    setScrollToMessageId(
                        previousResult?.cachedUserConversationHistory[0]?._id,
                    );
                }

                if (
                    !fetchMoreResult?.cachedUserConversationHistory ||
                    fetchMoreResult.cachedUserConversationHistory.length === 0
                ) {
                    setHasMore(false);
                    return previousResult;
                }

                const prevData = previousResult.cachedUserConversationHistory;
                const moreData = fetchMoreResult.cachedUserConversationHistory;

                if (moreData[moreData.length - 1]?._id) {
                    setScrollToMessageId(moreData[moreData.length - 1]?._id);
                }

                return {
                    ...previousResult,
                    cachedUserConversationHistory: [...moreData, ...prevData], // TODO: swapped order
                };
            },
        });
    }, [
        setScrollToMessageId,
        setHasMore,
        cachedConversationHistoryData,
        fetchMoreCachedConversationHistory,
        recentConversationHistoryLoading,
        cachedConversationHistoryLoading,
    ]);

    const selectClient = React.useCallback(
        (client: ClientWithPatients | null) => {
            setSelectedClient(client);
            if (client?.id) {
                sessionStorage.setItem(TEXTING_SELECTED_CLIENT, client.id);
            }
        },
        [setSelectedClient],
    );

    React.useEffect(() => {
        if (recentLocationConversationsData?.recentLocationConversations) {
            const unsortedConversations =
                recentLocationConversationsData.recentLocationConversations;

            switch (recentConversationSort) {
                case 'newestToOldest':
                    setSortedRecentLocationConversations(
                        [...unsortedConversations].sort((c1, c2) =>
                            c1.datetime > c2.datetime ? -1 : 1,
                        ),
                    );
                    break;
                case 'oldestToNewest':
                    setSortedRecentLocationConversations(
                        [...unsortedConversations].sort((c1, c2) =>
                            c1.datetime > c2.datetime ? 1 : -1,
                        ),
                    );
                    break;
                case 'unread':
                default:
                    // Functions handles sorting by unread, then newest to oldest
                    setSortedRecentLocationConversations(unsortedConversations);
                    break;
            }
        }
    }, [
        recentConversationSort,
        setSortedRecentLocationConversations,
        recentLocationConversationsData,
    ]);

    React.useEffect(() => {
        let unsubscribe = () => {};

        const loadTextMessageEvents = async () => {
            const conversationalSmsEventsCollection = await getCollection(
                TEXT_MESSAGING_EVENTS_FIREBASE_COLLECTION_NAME,
            );

            if (conversationalSmsEventsCollection && selectedClient?.id) {
                const queryConstraints: QueryConstraint[] = [
                    where(
                        'vetspireClientIds',
                        'array-contains',
                        selectedClient.id,
                    ),
                    where('createdAt', '>=', subSeconds(new Date(), 15)),
                ];

                const sessionQuery = query(
                    conversationalSmsEventsCollection,
                    ...queryConstraints,
                );

                unsubscribe = onSnapshot(sessionQuery, (snapshot) => {
                    if (snapshot.size > 0) {
                        if (snapshot.docs[0].id !== lastEventId) {
                            setLastEventId(snapshot.docs[0].id);

                            setEventTriggeredReload(true);

                            resyncRecentConversations().then(() => {
                                setScrollToMessageId(null);
                                refetchCachedConversationHistory().then();
                                refetchRecentLocationConversations().then();
                                refetchOpenTicketStatus().then();

                                setEventTriggeredReload(false);
                            });
                        }
                    }
                });
            }
        };

        loadTextMessageEvents().then();

        return unsubscribe;
    }, [
        resyncRecentConversations,
        refetchCachedConversationHistory,
        refetchOpenTicketStatus,
        refetchRecentLocationConversations,
        selectedClient,
        cachedConversationHistoryLoading,
        getCollection,
        lastEventId,
    ]);

    // If the client changes, reset hasMore status back to true
    React.useEffect(() => {
        if (selectedClient) {
            setHasMore(true);
        }
    }, [selectedClient]);

    const selectedClientIsRecent: boolean = React.useMemo(() => {
        if (!selectedClient) {
            return false;
        }

        const recentClientIds = (
            recentLocationConversationsData?.recentLocationConversations || []
        ).map((info) => info.vetspireClientId);

        return recentClientIds.includes(selectedClient.id);
    }, [selectedClient, recentLocationConversationsData]);

    const dropdownPlaceholder = React.useMemo(() => {
        return (
            <div className={styles.sortByLabel}>
                <span>
                    {translate(
                        'vetspireExtension.texting.sort.label',
                    ).toString()}
                </span>
                <span className={styles.selection}>
                    {translate(
                        `vetspireExtension.texting.sort.${recentConversationSort}`,
                    ).toString()}
                </span>
            </div>
        );
    }, [translate, recentConversationSort]);

    return (
        <div className={styles.container}>
            <div className={styles.searchPane}>
                <div className={styles.searchFields}>
                    <h1 className={styles.title}>
                        {translate('vetspireExtension.texting.title')}
                        <button
                            onClick={() => setShowFaqs(!showFaqs)}
                            type="button"
                            className={styles.faqButton}
                        >
                            {translate('vetspireExtension.texting.faqButton')}
                        </button>
                    </h1>
                    <ClientSearch setSelectedClient={selectClient} />
                    <Dropdown
                        id="sortByDropdown"
                        placeholder={dropdownPlaceholder}
                        value={recentConversationSort}
                        onChange={(newValue) => {
                            setRecentConversationSort(
                                newValue as RecentConversationsSort,
                            );
                        }}
                        options={SORTS.map((sort) => ({
                            id: sort,
                            label: translate(
                                `vetspireExtension.texting.sort.${sort}`,
                            ).toString(),
                        }))}
                        controlShouldRenderValue={false}
                    />
                </div>
                <RecentLocationConversations
                    selectedClient={selectedClient}
                    setSelectedClient={selectClient}
                    recentConversationInfo={
                        sortedRecentLocationConversations || []
                    }
                    loading={
                        recentLocationConversationsLoading &&
                        !sortedRecentLocationConversations
                    }
                />
            </div>

            <ConversationPane
                className={styles.conversationPane}
                selectedClient={selectedClient}
                setSelectedClient={selectClient}
                latestMessages={
                    cachedConversationHistoryLoading
                        ? []
                        : cachedConversationHistoryData?.cachedUserConversationHistory ||
                          []
                }
                refetchLatestMessages={refetchCachedConversationHistory}
                markAsReadOrUnread={markAsReadOrUnread}
                loadMore={loadMore}
                latestMessagesLoading={cachedConversationHistoryLoading}
                recentConversationHistoryLoading={
                    recentConversationHistoryLoading
                }
                updatingConversationUnreadStatus={
                    updatingConversationUnreadStatus ||
                    loadingUpdateConversationUnreadStatus
                }
                unread={unread}
                hasMore={hasMore}
                scrollToMessage={scrollToMessageId}
                hasOpenTicket={hasOpenTicketsData?.phoneNumberHasOpenTickets}
                hasOpenTicketLoading={hasOpenTicketsLoading}
                eventTriggeredReload={eventTriggeredReload}
                initialRecentHistorySync={initialRecentHistorySync}
                recent={selectedClientIsRecent}
            />
            <FaqModal showFaqs={showFaqs} setShowFaqs={setShowFaqs} />
        </div>
    );
};

export default Texting;
