import * as React from 'react';

import {
    ProactiveMessagingVariable,
    TemplateVariableValue,
} from '@bondvet/types/textMessaging';
import get from 'lodash/get';

import { ClientWithPatients } from '../../api';

import VariableInput from './VariableInput';

interface ClientFieldsProps {
    client: ClientWithPatients;
    activeVariables: ReadonlyArray<ProactiveMessagingVariable>;
    overrideValues: ReadonlyArray<TemplateVariableValue>;
    updateOverrideValues: (
        identifier: string,
        value: string,
        updateImmediately?: boolean,
    ) => void;
    errors?: {
        [key: string]: string | undefined;
    };
}

export default function ClientFields({
    client,
    activeVariables,
    overrideValues,
    updateOverrideValues,
    errors,
}: ClientFieldsProps): React.ReactElement | null {
    const lastClientId = React.useRef<string | null>(null);
    React.useEffect(() => {
        if (client?.id && lastClientId.current !== client.id) {
            activeVariables.forEach(({ identifier, vetspirePath }) => {
                if (vetspirePath) {
                    const newValue = get(client, vetspirePath, '');
                    updateOverrideValues(identifier, newValue);
                }
            });
            lastClientId.current = client.id;
        }
    }, [activeVariables, client, updateOverrideValues]);

    const renderVariable = React.useCallback(
        (variable: ProactiveMessagingVariable) => {
            const { identifier } = variable;

            const variableValue = overrideValues.find(
                (v) => v.identifier === identifier,
            );

            return (
                <li key={variable.identifier}>
                    <VariableInput
                        variable={variable}
                        value={variableValue?.value || ''}
                        onChange={(newValue) =>
                            updateOverrideValues(variable.identifier, newValue)
                        }
                        error={errors?.[variable.identifier]}
                    />
                </li>
            );
        },
        [overrideValues, errors, updateOverrideValues],
    );

    if (activeVariables.length === 0) {
        return null;
    }

    return <>{activeVariables.map((variable) => renderVariable(variable))}</>;
}
