import useClientUpdates from './useClientUpdates';
import useLateCancelReschedule from './useLateCancelReschedule';
import useNewOrderInterceptor from './useNewOrderInterceptor';
import useSuki from './useSuki';
import useVetspireSettings from './useVetspireSettings';
import useTextingRestrictions from './useTextingRestrictions';

export default function useGlobalEffects() {
    // we don't need any Vetspire settings here but that's the best place to trigger the hook
    // and distribute the settings to the tweaks by a post message
    useVetspireSettings();

    useClientUpdates();
    useLateCancelReschedule();
    useNewOrderInterceptor();
    useSuki();
    useTextingRestrictions();
}
