import * as React from 'react';
import get from 'lodash/get';

import { Autocomplete, TextField } from '@mui/material';
import classnames from 'classnames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    LocationConversationPet,
    PatientPronounType,
    ProactiveMessagingVariable,
    TemplateVariableValue,
} from '@bondvet/types/textMessaging';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import { VetspireSex } from '@bondvet/types/vetspire';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ClientWithPatients } from '../../api';

import VariableInput from './VariableInput';

import styles from './VariableInput.module.scss';

function getPatientPronoun(
    type: PatientPronounType | undefined,
    sex: VetspireSex | undefined,
    fallbackValue: string,
): string {
    if (sex === 'MALE') {
        switch (type) {
            case 'objective':
                return 'him';
            case 'subjective':
                return 'he';
            case 'possessive':
                return 'his';
            default:
                return fallbackValue;
        }
    } else if (sex === 'FEMALE') {
        switch (type) {
            case 'objective':
                return 'her';
            case 'subjective':
                return 'she';
            case 'possessive':
                return 'her';
            default:
                return fallbackValue;
        }
    } else {
        switch (type) {
            case 'objective':
                return 'them';
            case 'subjective':
                return 'your pet';
            case 'possessive':
                return 'their';
            default:
                return fallbackValue;
        }
    }
}

interface PatientSelectProps {
    client: ClientWithPatients;
    selectedPatientId?: string | undefined;
    onChange: (newPatient: LocationConversationPet) => void;
    petName: string;
    activeVariables: ReadonlyArray<ProactiveMessagingVariable>;
    overrideValues: ReadonlyArray<TemplateVariableValue>;
    updateOverrideValues: (
        identifier: string,
        value: string,
        updateImmediately?: boolean,
    ) => void;
    onNameChange?: (newName: string) => void;
    errors?: {
        [key: string]: string | undefined;
    };
}

export default function PatientSelect({
    client,
    selectedPatientId,
    onChange,
    petName,
    activeVariables,
    overrideValues,
    updateOverrideValues,
    onNameChange,
    errors,
}: PatientSelectProps): React.ReactElement | null {
    const translate = useTranslate();

    const lastPatientId = React.useRef<string | null>(null);

    const petNameVariable = activeVariables.find(
        ({ identifier }) => identifier === 'pet_name',
    );

    const valid =
        petName && petName.length <= (petNameVariable?.maxCharacters ?? 0);

    const activePatients = React.useMemo(
        () => (client.pets || []).filter((p) => !p.inactive && !p.deceased),
        [client.pets],
    );

    const handleChange = (event: React.SyntheticEvent, value: string) => {
        if (event?.type === 'click') {
            const newPatient = activePatients.find(
                ({ name }) => name === value,
            );

            if (newPatient) {
                onChange(newPatient);
            }
            onNameChange?.(value);
        } else if (event?.type === 'change') {
            onNameChange?.(value);
        }
    };

    const handleChangeNoPetName = (newValue: string) => {
        const newPatient = activePatients.find(({ id }) => id === newValue);

        if (newPatient) {
            onChange(newPatient);
        }
    };

    React.useEffect(() => {
        if (selectedPatientId && lastPatientId.current !== selectedPatientId) {
            const selectedPatient = client.pets.find(
                ({ id }) => selectedPatientId === id,
            );

            if (selectedPatient) {
                activeVariables.forEach(
                    ({ identifier, vetspirePath, pronounType }) => {
                        if (vetspirePath && !pronounType) {
                            const newValue = get(
                                selectedPatient,
                                vetspirePath,
                                '',
                            );
                            updateOverrideValues(identifier, newValue, true);
                        } else if (pronounType) {
                            const newValue = getPatientPronoun(
                                pronounType,
                                selectedPatient.sex,
                                `{{${identifier}}}`,
                            );
                            updateOverrideValues(identifier, newValue, true);
                        }
                    },
                );
                lastPatientId.current = client.id;
            }
        }
    }, [activeVariables, selectedPatientId, client, updateOverrideValues]);

    const renderVariable = React.useCallback(
        (variable: ProactiveMessagingVariable) => {
            const { identifier } = variable;

            if (identifier === 'pet_name') {
                return null;
            }
            const variableValue = overrideValues.find(
                (v) => v.identifier === identifier,
            );

            return (
                <li key={variable.identifier}>
                    <VariableInput
                        variable={variable}
                        value={variableValue?.value || ''}
                        onChange={(newValue) =>
                            updateOverrideValues(variable.identifier, newValue)
                        }
                        error={errors?.[variable.identifier]}
                    />
                </li>
            );
        },
        [overrideValues, errors, updateOverrideValues],
    );

    if (activeVariables.length === 0) {
        return null;
    }

    return (
        <>
            <li>
                <div className={classnames(styles.root, styles.buffer)}>
                    {petNameVariable ? (
                        <>
                            <div className={styles.input}>
                                <label>Pet Name</label>
                                <Autocomplete
                                    value={petName}
                                    freeSolo
                                    options={activePatients.map(
                                        (option) => option.name,
                                    )}
                                    classes={{
                                        inputRoot: classnames({
                                            [styles.error]:
                                                errors &&
                                                Object.keys(errors).length > 0,
                                        }),
                                    }}
                                    onInputChange={handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={
                                                translate(
                                                    'vetspireExtension.texting.sendOutboundMessageModal.variables.selectPetPlaceholder',
                                                ) as string
                                            }
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: null,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                                {errors && errors.pet_name && (
                                    <div className={styles.errorText}>
                                        {errors.pet_name}
                                    </div>
                                )}
                            </div>
                            <div
                                className={classnames(
                                    styles.iconWrapper,
                                    styles.smallBuffer,
                                    {
                                        [styles.valid]: valid,
                                    },
                                )}
                            >
                                <CheckCircleIcon />
                            </div>
                            <div className={styles.characterCount}>
                                <span
                                    className={classnames({
                                        [styles.invalid]: !valid,
                                    })}
                                >
                                    {petName.length}
                                </span>
                                /{petNameVariable.maxCharacters}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.input}>
                                <label>Pet</label>
                                <Select
                                    fullWidth
                                    placeholder={
                                        translate(
                                            'vetspireExtension.texting.sendOutboundMessageModal.variables.selectPetPlaceholder',
                                        ) as string
                                    }
                                    value={selectedPatientId ?? ''}
                                    onChange={(event) => {
                                        handleChangeNoPetName(
                                            event.target.value as string,
                                        );
                                    }}
                                >
                                    {activePatients.map((pet) => (
                                        <MenuItem key={pet.id} value={pet.id}>
                                            {pet.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div
                                className={classnames(
                                    styles.iconWrapper,
                                    styles.smallBuffer,
                                    {
                                        [styles.valid]: !!selectedPatientId,
                                    },
                                )}
                            >
                                <CheckCircleIcon />
                            </div>
                        </>
                    )}
                </div>
            </li>
            {activeVariables.map((variable) => renderVariable(variable))}
        </>
    );
}
