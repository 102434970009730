import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import { WithFlyout } from 'lib/types';
import { CreditCard } from 'api/types';
import styles from './CreditCards.module.scss';
import AddCreditCard from './components/AddCreditCard';
import useClientHasPatientsWithActivePlans from './hooks/useClientHasPatientsWithActivePlans';
import useCreditCards from './hooks/useCreditCards';
import Card from './components/Card';
import Reload from './components/Reload';
import DueInvoices from './components/DueInvoices';
import useDueInvoices from './hooks/useDueInvoices';

export type CreditCardsProps = {
    classes?: Partial<WithFlyout<typeof styles>>;
    clientId: string;
    chargeNoShowFees: boolean;
};

function CreditCards({
    classes,
    clientId,
    chargeNoShowFees,
}: CreditCardsProps): React.ReactElement {
    const {
        creditCards,
        deleteCard,
        refetch: refetchCreditCards,
        isLoading: isLoadingCreditCards,
        setDefault,
    } = useCreditCards(clientId);
    const {
        orders,
        refetch: refetchDueInvoices,
        isLoading: isLoadingDueInvoices,
    } = useDueInvoices(clientId);
    const translate = useTranslate();

    const { clientHasPatientsWithActivePlans } =
        useClientHasPatientsWithActivePlans(clientId);

    const onReload = React.useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            refetchCreditCards();
            refetchDueInvoices();
        },
        [refetchCreditCards, refetchDueInvoices],
    );

    const isLoading = isLoadingCreditCards || isLoadingDueInvoices;

    const defaultCard = React.useMemo<CreditCard | null>(
        () => creditCards.find((card) => card.isDefault) || null,
        [creditCards],
    );

    return (
        <>
            <header className={classnames(styles.flyoutBlock, styles.first)}>
                <span className={styles.flyoutTitle}>
                    {translate(
                        'vetspireExtension.clientDetails.payments.creditCards.title',
                    )}
                </span>
            </header>
            <div className={classnames(classes?.root, styles.root)}>
                <Reload onClick={onReload} isLoading={isLoading} />

                {creditCards.map((card) => (
                    <Card
                        card={card}
                        key={card.id}
                        onDelete={() => deleteCard(card.id)}
                        onSetDefault={() => setDefault(card.id)}
                        chargeNoShowFees={chargeNoShowFees}
                        clientHasPatientsWithActivePlans={
                            clientHasPatientsWithActivePlans
                        }
                    />
                ))}

                <AddCreditCard clientId={clientId} />
            </div>
            <DueInvoices orders={orders} defaultCard={defaultCard} />
        </>
    );
}

export default CreditCards;
