import { closeFlyout, openFlyout, Page } from 'lib/vetspireActions';
import ChargeFee from 'pages/ClientDetails/components/ChargeFee';
import * as React from 'react';
import useClientAndPatientId from 'hooks/useClientAndPatientId';
import useTranslate from 'hooks/useTranslate';
import Alert from 'components/Alert';
import CreditCards from 'components/CreditCards';
import Credits from 'components/Credits';
import Duplicates from 'components/Duplicates';
import { ProviderRightValue } from '@bondvet/types/providers';
import useViewerSettings from 'hooks/useViewerSettings';
import classnames from 'classnames';
import Tabs from 'components/Tabs';
import { useLocation } from 'react-router-dom';
import { FeeProductType } from '@bondvet/types/noShows';
import LifetimeStats from './components/LifetimeStats';
import styles from './ClientDetails.module.scss';

type ClientDetailsTab = 'payments' | 'credits';

const TABS: ReadonlyArray<ClientDetailsTab> = ['payments', 'credits'];

function ClientDetails(): React.ReactElement {
    const { search } = useLocation();

    const urlParams = new URLSearchParams(search);

    const { clientId } = useClientAndPatientId(
        urlParams.get('clientId'),
        urlParams.get('patientId'),
    );

    const translate = useTranslate();
    const viewerSettings = useViewerSettings();
    const [currentTab, setCurrentTab] =
        React.useState<ClientDetailsTab>('payments');

    const tabs = React.useMemo(
        () =>
            TABS.map((name) => ({
                name,
                label: translate(
                    `vetspireExtension.clientDetails.tabs.${name}`,
                ),
            })),
        [translate],
    );

    const changeCurrentTab = React.useCallback((tab: string) => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setCurrentTab(tab as ClientDetailsTab);
            });
        });
    }, []);

    const closeChargeFee = React.useCallback(
        (closePage: boolean) => {
            if (closePage) {
                closeFlyout();
            } else {
                const params = new URLSearchParams();
                params.set('clientId', clientId || '');

                openFlyout(Page.clientDetails, `?${params.toString()}`);
            }
        },
        [clientId],
    );

    const feeProductType = (urlParams.get('feeProductType') ||
        null) as FeeProductType | null;

    return (
        <div className={classnames(styles.flyout, styles.container)}>
            {clientId === null ? (
                <Alert>
                    {translate('vetspireExtension.clientDetails.noClientRoute')}
                </Alert>
            ) : (
                <>
                    <header
                        className={classnames(styles.flyoutBlock, styles.first)}
                    >
                        <span className={styles.flyoutTitle}>
                            {translate('vetspireExtension.clientDetails.title')}
                        </span>
                    </header>

                    <Tabs
                        currentTab={currentTab}
                        onChange={changeCurrentTab}
                        tabs={tabs}
                    />
                    <LifetimeStats clientId={clientId} />
                    {currentTab === 'payments' && (
                        <>
                            {viewerSettings.rights
                                ?.vetspireExtension_creditCards ===
                                ProviderRightValue.enabled && (
                                <>
                                    <CreditCards
                                        clientId={clientId}
                                        chargeNoShowFees={
                                            viewerSettings.rights
                                                ?.vetspireExtension_chargeNoShowFees ===
                                            ProviderRightValue.enabled
                                        }
                                    />
                                    <Duplicates clientId={clientId} />
                                </>
                            )}
                        </>
                    )}
                    {currentTab === 'credits' && (
                        <Credits clientId={clientId} />
                    )}
                    {feeProductType && clientId && (
                        <ChargeFee
                            clientId={clientId}
                            feeProductType={feeProductType}
                            onClose={closeChargeFee}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default ClientDetails;
