import {
    addBackgroundMessageListener,
    MESSAGE_SOURCE_NAVIGATION,
} from 'lib/backgroundMessages';
import * as React from 'react';
import {
    requestLocationId,
    VetspireExtensionMessage,
} from 'lib/vetspireActions';

const VETSPIRE_PATH_NAME_KEY = 'vetspire_path_name';
const VETSPIRE_HOST_NAME_KEY = 'vetspire_host_name';
const VETSPIRE_HOST_KEY = 'vetspire_host';
const VETSPIRE_PROTOCOL_KEY = 'vetspire_protocol';
const VETSPIRE_LOCATION_ID_KEY = 'vetspire_location_id';

type LocationData = {
    pathName: string;
    hostName: string;
    host: string;
    protocol: string;
};

export function setVetspireLocationData(
    locationData: Partial<LocationData>,
): void {
    sessionStorage.setItem(VETSPIRE_PATH_NAME_KEY, locationData.pathName ?? '');
    sessionStorage.setItem(VETSPIRE_HOST_NAME_KEY, locationData.hostName ?? '');
    sessionStorage.setItem(VETSPIRE_HOST_KEY, locationData.host ?? '');
    sessionStorage.setItem(VETSPIRE_PROTOCOL_KEY, locationData.protocol ?? '');
}

export function setVetspireLocationId(locationId: string): void {
    sessionStorage.setItem(VETSPIRE_LOCATION_ID_KEY, locationId);
}

export function getVetspireLocationData(): LocationData {
    return {
        pathName: sessionStorage.getItem(VETSPIRE_PATH_NAME_KEY) ?? '',
        hostName: sessionStorage.getItem(VETSPIRE_HOST_NAME_KEY) ?? '',
        host: sessionStorage.getItem(VETSPIRE_HOST_KEY) ?? '',
        protocol: sessionStorage.getItem(VETSPIRE_PROTOCOL_KEY) ?? '',
    };
}

export function getVetspirePathName(): string {
    return sessionStorage.getItem(VETSPIRE_PATH_NAME_KEY) || '';
}

export function getVetspireLocationId(): string {
    return sessionStorage.getItem(VETSPIRE_LOCATION_ID_KEY) || '';
}

export function getVetspireHostName(): string {
    return sessionStorage.getItem(VETSPIRE_HOST_NAME_KEY) || '';
}

export function getVetspireHost(): string {
    return sessionStorage.getItem(VETSPIRE_HOST_KEY) || '';
}

export function getVetspireProtocol(): string {
    return sessionStorage.getItem(VETSPIRE_PROTOCOL_KEY) || '';
}

interface VetspireContextData {
    pathName: string;
    hostName: string;
    host: string;
    protocol: string;
    locationId: string;
}

export const vetspireContext = React.createContext<VetspireContextData>({
    pathName: getVetspirePathName(),
    hostName: getVetspireHostName(),
    host: getVetspireHost(),
    protocol: getVetspireProtocol(),
    locationId: getVetspireLocationId(),
});

const VetspireContextProvider: React.FunctionComponent<
    React.PropsWithChildren
> = ({ children }) => {
    const [{ pathName, hostName, host, protocol }, setLocationData] =
        React.useState(getVetspireLocationData());
    const [locationId, setLocationId] = React.useState(getVetspireLocationId());

    const messageListener = React.useCallback(
        (message: VetspireExtensionMessage) => {
            switch (message.action) {
                case 'pathNameChanged': {
                    const newLocationData = {
                        pathName: message.pathName ?? '',
                        hostName: message.hostName ?? '',
                        host: message.host ?? '',
                        protocol: message.protocol ?? '',
                    };
                    setLocationData(newLocationData);
                    setVetspireLocationData(newLocationData);
                    break;
                }
                case 'locationIdChanged': {
                    const newLocationId = message.locationId || '';
                    setLocationId(newLocationId);
                    break;
                }
                default:
                    break;
            }
        },
        [],
    );

    React.useEffect(() => {
        const unsubscribe = addBackgroundMessageListener(
            MESSAGE_SOURCE_NAVIGATION,
            messageListener,
        );
        // make sure we know the currently selected location ID
        requestLocationId();

        return unsubscribe;
    }, [messageListener]);

    return (
        <vetspireContext.Provider
            value={{
                pathName,
                locationId,
                hostName,
                host,
                protocol,
            }}
        >
            {children}
        </vetspireContext.Provider>
    );
};

export default VetspireContextProvider;
