import * as React from 'react';
import { CreditCard } from 'api/types';
import { dueInvoices, Order } from 'api/clients/queries';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import useTranslate from 'hooks/useTranslate';
import { useMutation } from '@apollo/client';
import {
    chargeInvoice as chargeInvoiceMutation,
    ChargeInvoiceResult,
    ChargeInvoiceVariables,
} from 'api/clients/mutations';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { ExecutableDefinitionNode } from 'graphql/language/ast';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAnalytics } from 'hooks/useAnalytics';
import { Page } from 'lib/vetspireActions';
import { formatAmountAsUSD } from '../../util';
import styles from './ChargeInvoice.module.scss';

type ChargeInvoiceProps = {
    defaultCard: CreditCard;
    invoice: Order;
    onClose: () => void;
};

export default function ChargeInvoice({
    defaultCard,
    invoice,
    onClose,
}: ChargeInvoiceProps): React.ReactElement {
    const analytics = useAnalytics();
    const translate = useTranslate();
    const [runChargeInvoiceMutation, { loading, data }] = useMutation<
        ChargeInvoiceResult,
        ChargeInvoiceVariables
    >(chargeInvoiceMutation, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.creditCards },
        refetchQueries: dueInvoices.definitions
            .map(
                (definition) =>
                    (definition as ExecutableDefinitionNode).name?.value || '',
            )
            .filter((name) => name !== ''),
    });
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        if (loading) {
            setError('');
        } else if (data?.chargeInvoice.success) {
            setError('');
        } else if (data?.chargeInvoice.error) {
            setError(data.chargeInvoice.error);
        }
    }, [loading, data]);

    const handleSubmit = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            runChargeInvoiceMutation({ variables: { invoiceId: invoice.id } });
            analytics.trackEvent(Page.clientDetails, 'charge_out_invoice');
        },
        [runChargeInvoiceMutation, invoice, analytics],
    );

    /* eslint-disable no-nested-ternary */

    return (
        <Dialog open={Boolean(defaultCard)}>
            {loading && (
                <DialogContent
                    className={classNames(styles.status, styles.loading)}
                >
                    <CircularProgress />
                    <DialogContentText>
                        {translate(
                            'vetspireExtension.chargeInvoice.processing',
                        )}
                    </DialogContentText>
                </DialogContent>
            )}
            {!loading && error && (
                <DialogContent
                    className={classNames(styles.error, styles.status)}
                >
                    <CancelIcon />
                    <DialogContentText>
                        <strong>
                            {translate(
                                'vetspireExtension.chargeInvoice.failed',
                            )}
                        </strong>
                        {error}
                    </DialogContentText>

                    <DialogActions>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {translate('vetspireExtension.chargeInvoice.retry')}
                        </Button>
                        <Button type="button" onClick={onClose}>
                            {translate('vetspireExtension.chargeInvoice.close')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            )}

            {!loading && data?.chargeInvoice.success && (
                <DialogContent
                    className={classNames(styles.status, styles.success)}
                >
                    <CheckIcon />
                    {translate('vetspireExtension.chargeInvoice.success')}
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={onClose}
                    >
                        {translate('vetspireExtension.chargeInvoice.close')}
                    </Button>
                </DialogContent>
            )}

            {!loading && !error && !data?.chargeInvoice.success && (
                <>
                    <DialogTitle>
                        {translate('vetspireExtension.chargeInvoice.title')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {translate(
                                'vetspireExtension.chargeInvoice.content',
                                {
                                    card: defaultCard.last4,
                                    amount: formatAmountAsUSD(
                                        invoice.dueAmount,
                                    ),
                                },
                            )}
                        </DialogContentText>
                        <DialogActions>
                            <Button type="button" onClick={onClose}>
                                {translate(
                                    'vetspireExtension.chargeInvoice.cancel',
                                )}
                            </Button>
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {translate(
                                    'vetspireExtension.chargeInvoice.submit',
                                )}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
}
