import SelectedDay from 'pages/IntradayCapacity/components/SelectedDay/SelectedDay';
import * as React from 'react';
import { addMonths, isBefore, parse, format, toDate } from 'date-fns';
import MonthSelect from 'components/MonthSelect';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import styles from './IntradayCapacity.module.scss';
import MonthOverview from './components/MonthOverview';
import useIntradayCapacity from './hooks/useIntradayCapacity';
import { MONTH_FORMAT } from './types';

const MIN_MONTH = '2024-08';

export default function IntradayCapacity() {
    const translate = useTranslate();
    const [selectedMonth, setSelectedMonth] = React.useState<string>(() =>
        format(new Date(), MONTH_FORMAT),
    );
    const { capacities, appointments, isAdmin, viewerName } =
        useIntradayCapacity(selectedMonth);
    const [selectedDate, setSelectedDate] = React.useState<string | null>(null);

    const onSelectDate = React.useCallback((date: string) => {
        setSelectedDate((prev) => {
            if (prev === date) {
                return null;
            }

            return date;
        });
    }, []);

    // clear selected date when month changes
    React.useEffect(() => {
        setSelectedDate(null);
    }, [selectedMonth]);

    const { minMonth, maxMonth } = React.useMemo(() => {
        const today = toDate(new Date());

        let min = addMonths(today, -24);

        if (isBefore(min, parse(MIN_MONTH, MONTH_FORMAT, new Date()))) {
            min = parse(MIN_MONTH, MONTH_FORMAT, new Date());
        }

        return {
            minMonth: format(min, MONTH_FORMAT),
            maxMonth: format(addMonths(today, 3), MONTH_FORMAT),
        };
    }, []);

    return (
        <div>
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.intradayCapacity.title')}
                </div>
            </div>
            <div className={styles.flyoutBlock}>
                <MonthSelect
                    month={selectedMonth}
                    onChange={setSelectedMonth}
                    minMonth={minMonth}
                    maxMonth={maxMonth}
                />
            </div>
            <div className={classnames(styles.floutBlock, styles.hint)}>
                <p>{translate('vetspireExtension.intradayCapacity.hint')}</p>
            </div>
            <div className={styles.flyoutBlock}>
                <MonthOverview
                    month={selectedMonth}
                    onSelectDate={onSelectDate}
                    capacities={capacities}
                    selectedDate={selectedDate}
                    isAdmin={isAdmin}
                    viewerName={viewerName}
                />
            </div>

            {selectedDate && (
                <SelectedDay
                    date={selectedDate}
                    capacities={capacities[selectedDate]}
                    bookedAppointments={appointments}
                />
            )}
        </div>
    );
}
