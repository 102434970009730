import { gql } from '@apollo/client';

export type Provider = {
    id: string;
    username: string;
    name: string;
    email: string;
    isActive: boolean;
    isVeterinarian: boolean;
    givenName: string | null;
    familyName: string | null;
    defaultLocationId: string | null;
    locations: null | readonly { id: string }[];
};

export type ProvidersQueryResult = {
    providers: ReadonlyArray<Provider>;
};

export type ProvidersQueryVariables = {
    locationId?: string | null;
    onlyActive: boolean;
};

const providersFields = `
id
username
name
email
isActive
isVeterinarian
givenName
familyName
defaultLocationId
locations { id }
`;

export const providersQuery = gql`
    query providers($locationId: ID) {
        providers(locationId: $locationId) {
            ${providersFields}
        }
    }
`;

export type ViewerQueryResult = {
    viewer: Provider;
};

export const viewerQuery = gql`
    query {
        viewer {
            ${providersFields}
        }
    }
`;

export const impactScoreV2 = gql`
    query impactScoreV2($locationId: InternalId, $date: DateString) {
        impactScoreV2(
            locationId: $locationId
            date: $date
            application: "VS Extension"
        ) {
            locationId
            vetspireLocationId
            date
            visits
            visitsMilestone1
            visitsMilestone2
            visits100PercentValue
            visitsPercent
            visitsMilestone1Percent
            visitsMilestone2Percent
            revenuePerDoctor
            revenueMilestone1
            revenuePerDoctorMilestone1
            revenuePerDoctorMilestone2
            revenuePerDoctorPercent
            revenuePerDoctorMilestone1Percent
            revenuePerDoctorMilestone2Percent
            revenuePerDoctor100PercentValue
            payoutMilestone1
            payoutMilestone2
            milestone1Reached
            milestone2Reached
            payout
            payoutEmployees {
                name
            }
            payoutOfMonth
            bonusPercentMilestone1
            bonusPercentMilestone2
        }
    }
`;
