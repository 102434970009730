import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ReloadIcon from '@mui/icons-material/Autorenew';
import Tooltip from '@mui/material/Tooltip';
import useTranslate from 'hooks/useTranslate';
import styles from './Reload.module.scss';

export type ReloadProps = {
    onClick: (event: React.SyntheticEvent) => void;
    isLoading: boolean;
};

function Reload({ onClick, isLoading }: ReloadProps): React.ReactElement {
    const translate = useTranslate();

    return (
        <Tooltip
            title={translate(
                'vetspireExtension.clientDetails.payments.creditCards.reload',
            )}
            placement="right"
        >
            <div className={styles.root}>
                <IconButton disabled={isLoading} onClick={onClick} size="large">
                    <ReloadIcon />
                </IconButton>
                {isLoading && (
                    <CircularProgress
                        size={51}
                        classes={{ root: styles.progress }}
                    />
                )}
            </div>
        </Tooltip>
    );
}

export default Reload;
