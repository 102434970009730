import * as React from 'react';
import {
    ClientAppointment,
    clientAppointmentsQuery,
    ClientAppointmentsQueryResult,
    ClientAppointmentsQueryVariables,
} from 'api/clients/queries';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { format, startOfDay } from 'date-fns';
import { ProactiveMessagingVariable } from '@bondvet/types/textMessaging';
import classnames from 'classnames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import styles from './VariableInput.module.scss';

interface AppointmentFieldsProps {
    clientId: string;
    selectedAppointmentId: string | undefined;
    selectedLocationId: string | undefined;
    onChange: (newLocation: ClientAppointment) => void;
    activeVariables: ReadonlyArray<ProactiveMessagingVariable>;
    error?: string | null;
}

export default function AppointmentFields({
    clientId,
    selectedAppointmentId,
    selectedLocationId,
    onChange,
    activeVariables,
    error,
}: AppointmentFieldsProps): React.ReactElement | null {
    const translate = useTranslate();
    const valid = !!selectedAppointmentId;
    const start = React.useMemo(() => startOfDay(new Date()), []);
    const { data, loading } = useQuery<
        ClientAppointmentsQueryResult,
        ClientAppointmentsQueryVariables
    >(clientAppointmentsQuery, {
        fetchPolicy: 'cache-first',
        variables: {
            clientId,
            locationId: selectedLocationId,
            start,
        },
    });

    const appointments = React.useMemo(() => data?.appointments, [data]);

    const handleOnChange = React.useCallback(
        (appointmentId: string) => {
            const appointment = (appointments || []).find(
                (a) => a.id === appointmentId,
            );
            if (appointment) {
                onChange(appointment);
            }
        },
        [appointments, onChange],
    );

    if (activeVariables.length === 0) {
        return null;
    }

    if (appointments && !loading) {
        return (
            <>
                <li>
                    <div className={classnames(styles.root, styles.buffer)}>
                        <div
                            className={classnames(styles.input, {
                                [styles.error]: !!error,
                            })}
                        >
                            <label>Appointment</label>
                            <Select
                                fullWidth
                                placeholder={
                                    translate(
                                        'vetspireExtension.texting.sendOutboundMessageModal.variables.selectAppointmentPlaceholder',
                                    ) as string
                                }
                                disabled={loading}
                                value={selectedAppointmentId ?? ''}
                                onChange={(event) =>
                                    handleOnChange(event.target.value as string)
                                }
                                classes={{
                                    root: classnames({
                                        [styles.error]: !!error,
                                    }),
                                }}
                            >
                                {appointments.map((appointment) => (
                                    <MenuItem
                                        key={appointment.id}
                                        value={appointment.id}
                                    >
                                        {`${format(new Date(appointment.start), 'MM/dd hh:mm aa')} at ${appointment.location.name} for ${appointment.patient.name}`}
                                    </MenuItem>
                                ))}
                            </Select>
                            {error && (
                                <div className={styles.errorText}>{error}</div>
                            )}
                        </div>
                        <div
                            className={classnames(
                                styles.iconWrapper,
                                styles.smallBuffer,
                                {
                                    [styles.valid]: valid,
                                },
                            )}
                        >
                            <CheckCircleIcon />
                        </div>
                    </div>
                </li>
            </>
        );
    }

    return <CircularProgress />;
}
