import useBondQuery from 'hooks/useBondQuery';
import * as React from 'react';
import { gql } from '@apollo/client';
import type { LocationSpecificVetspireSettings as BaseLocationSpecificVetspireSettings } from '@bondvet/types/vetspire';
import useLocationId from './useLocationId';

type LocationSpecificVetspireSettings = Omit<
    BaseLocationSpecificVetspireSettings,
    '_id'
>;

const defaultSettings: LocationSpecificVetspireSettings = {
    warnOnOptedOutTexting: false,
    disableNonDoctorTextMessaging: false,
};

const locationSpecificVetspireSettingsQuery = gql`
    query locationSpecificVetspireSettingsQuery($vetspireLocationId: ID!) {
        locationSpecificVetspireSettings(
            vetspireLocationId: $vetspireLocationId
        ) {
            warnOnOptedOutTexting
            disableNonDoctorTextMessaging
        }
    }
`;

type LocationSpecificVetspireSettingsQueryResult = {
    locationSpecificVetspireSettings: LocationSpecificVetspireSettings | null;
};

type LocationSpecificVetspireSettingsQueryVariables = {
    vetspireLocationId: string;
};

export default function useLocationSpecificVetspireSettings(): LocationSpecificVetspireSettings {
    const locationId = useLocationId();

    const { data } = useBondQuery<
        LocationSpecificVetspireSettingsQueryResult,
        LocationSpecificVetspireSettingsQueryVariables
    >(locationSpecificVetspireSettingsQuery, {
        fetchPolicy: 'cache-and-network',
        variables: {
            vetspireLocationId: locationId ?? '',
        },
        skip: !locationId || locationId === '',
    });

    const settings = React.useMemo(() => {
        if (data?.locationSpecificVetspireSettings) {
            return {
                ...defaultSettings,
                ...data.locationSpecificVetspireSettings,
            };
        }

        return defaultSettings;
    }, [data?.locationSpecificVetspireSettings]);

    return settings;
}
